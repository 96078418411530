@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');

@import 'npm:@rainbow-me/rainbowkit/styles.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #38428a;
    background-image: url('./img/mizukios_logo.png');
    background-repeat: no-repeat;
    background-position: center;
    color: #FFFFE7;
    font-family: "Koulen", sans-serif;
    text-align: center;
    padding-left:10px;
    padding-right:10px;
}

@media (min-width: 1024px) {
    body {
        padding-left: 0;
        padding-right: 0;
    }
}

h1 {
    margin-top: 30px;
    margin-bottom: 1px;
    font-size: 4rem;
}

.menubar-container {
    margin: 0 auto;
    width: 100%;
    max-width: 1080px;
    background-color: #C5C5C5;
    border-left: #F7F7F8 solid 4px;
    border-right: #372F3D solid 4px;
}

.traitbar {
    padding: 5px;
    color: #000;
}

.editor-container {
    margin: 0 auto;
    max-width: 1080px;
    background-color: #C5C5C5;
}

.mizuki-preview {
    border-top: 0;
    border-left: #F7F7F8 solid 4px;
    border-bottom: #372F3D solid 4px;
    border-right: #372F3D solid 4px;

    padding: 1rem;
}

.trait-selector-container {
    border-left: #F7F7F8 solid 4px;
    border-top: #F7F7F8 solid 4px;
    border-right: #372F3D solid 4px;
    border-bottom: #372F3D solid 4px;
}

.trait-selector {
    padding: 1rem;
    overflow-x: hidden;
    overflow-y: scroll;
}

@media (min-width: 1024px) {
    .mizuki-preview {
        border-left: #F7F7F8 solid 4px;
        border-bottom: #372F3D solid 4px;
        border-right:0;
    }

    .trait-selector-container {
        border-top: none;
        border-left: none;
        border-right: #372F3D solid 4px;
        border-bottom: #372F3D solid 4px;
    }
}

.trait-option {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
    padding: 20px;
    margin: 10px;

    cursor: pointer;
}

.trait-option > div {
    border: #FFF 4px solid;
    width: 100%;
    height: 100%;
}

.trait-option.selected > div {
    border: #dddd4c 4px solid;
}

button {
    cursor: pointer;
    padding: 10px;
    font-size: 20px;
}

.button-prev {
    float: left;
    width: 100px;
}

.button-next {
    float: right;
    width: 100px;
}

.image-buttons {
    height: 80px;
    padding: 6px;
}

.wordfile-container {
    margin: 0 auto;
    margin-top: 80px;
    max-width: 1080px;
    max-height: 600px;
    padding: 0.5rem;
    background-color: #C5C5C5;

    border-top: #F7F7F8 solid 3px;
    border-left: #F7F7F8 solid 3px;
    border-bottom: #372F3D solid 3px;
    border-right: #372F3D solid 3px;
}

.wordfile-header {
    width: 100%;
    padding: 6px;
    background-color: #142669;
    font-weight: bold;
    color: #FFF;
    text-align: left;
    font-family:Arial, Helvetica, sans-serif;
}

.whitepaper {
    margin: 0 auto;
    margin-top: 10px;
    max-width: 1080px;
    max-height: 520px;
    padding: 2rem;
    color: #000;
    background-color: #FFF;
    font-family:'Times New Roman', Times, serif;
    overflow: scroll;
    text-align: left;
}

.filesystem-label {
    font-family: Arial, Helvetica, sans-serif
}

.mizuki-selector-item {
    display: inline-block;
    width: 120px;
    height: 120px;
    margin: 12px;
    cursor: pointer;
}

.mizuki-selector-item.selected {
    border: #dddd4c 4px solid;
}

.windows-button {
    border-top: #F7F7F8 solid 2px;
    border-left: #F7F7F8 solid 2px;
    border-bottom: #372F3D solid 2px;
    border-right: #372F3D solid 2px;
}

.minter-container {
    margin: 0 auto;
    max-width: 1080px;
    background-color: #C5C5C5;
}

.mint-mizuki {
    border-top: 0;
    border-left: #F7F7F8 solid 4px;
    border-right: #372F3D solid 4px;

    padding: 1rem;
}

.mint-maker {
    border-left: #F7F7F8 solid 4px;
    border-right: #372F3D solid 4px;
    border-bottom: #372F3D solid 4px;

    padding: 1rem;

    overflow-x: hidden;
    overflow-y: scroll;
}

@media (min-width: 1024px) {
    .mint-mizuki {
        border-top: 0;
        border-left: #F7F7F8 solid 4px;
        border-bottom: #372F3D solid 4px;
    }

    .mint-maker {
        border-top: 0;
        border-right: #372F3D solid 4px;
        border-bottom: #372F3D solid 4px;
    }
}

.window-menubar {
    display: flex;

    margin: 0 auto;
    margin-top: 50px;
    max-width: 1080px;
    max-height: 600px;
    padding: 0.5rem;
    background-color: #C5C5C5;

    border-top: #F7F7F8 solid 4px;
    border-left: #F7F7F8 solid 4px;
    border-bottom: 0;
    border-right: #372F3D solid 4px;
}